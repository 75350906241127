/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 300;
    color: $text-color;
    background-color: $background-color;
    background-image: url('../images/bg.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    -webkit-text-size-adjust: 100%;
    margin-top: 170px;

    @include media-query($on_palm) {
        margin-top: 92px;
        background-image: none;
        
        &:before {
            content: "";
            position: fixed;
            top: 0;
            left: 0; 
            bottom: 0;
            right: 0;
            background-image: url('../images/bg.jpg');
            background-size: auto 100%;
            background-position: center;
            z-index: -1;
        }
    }
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}


.post  {
    h1:not(.blog-title), h2, h3, h4, h5, h6 {
        margin-bottom: $spacing-unit / 4;
        margin-top: $spacing-unit;
    }
}
/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-family: KlavikaWebBold;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.25em;
}


/**
 * Links
 */
a {
    color: $taps-blue;
    text-decoration: none;

    /*&:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }*/
}

a img {
    border: none;
}



/**
 * Blockquotes
 */
blockquote {
    color: white;
    padding: 20px 60px;
    font-size: 22px;
    font-style: italic;
    margin: 40px 0;
    border-radius: 10px;
    position: relative;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;

    p, h1, h2, h3, h4, h5, h6 {
        font-size: 22px !important;
        font-weight: 200;
        font-family: Helvetica, Arial, sans-serif;
    }

    @include blue-gradient();

    > :last-child {
        margin-bottom: 0;
    }

    @include media-query($on-laptop) {
        padding: 20px 60px;
      font-size: 20px;
    }

    @include media-query($on-palm) {
      padding: 10px 20px;
      margin: 20px 0;
      font-size: 18px;
    }

    &:after {
        content: "";
        width: 0;
        height: 0;
        border-top: 20px solid $light-blue;
        border-left: 20px solid transparent;
        position: absolute;
        bottom: -20px;
        right: 20px;
    }

    &.orange:after {
        border-top: 20px solid $light-orange;
    }

    &.green:after {
        border-top: 20px solid $light-green;
    }

    &.purple:after {
        border-top: 20px solid $light-purple;
    }
}

.color-white {
    color: white !important;
}

.width-auto {
    width: auto !important;
}

.width-50 {
    width: 50%;
}

.width-100 {
    width: 100% !important;
}

.center-text {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.font-style-normal {
    font-style: normal !important;
}

.float-left {
    float: left;
}

.float-none {
    float: none !important;
}

.margin-center {
    margin: 0 auto !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.margin-left-20 {
    margin-left: 20px !important;
}

.margin-right-20 {
    margin-right: 20px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-top-20 {
    margin-top: 20px;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-top-20 {
    padding-top: 20px !important;
}

.display-inline-block {
    display: inline-block;
}

.border {
    border: 1px solid #ccc;
}

.border-top {
    border-top: 1px solid #ccc;
}

.hr-margin-10 {
    margin: 10px 0;
}

#platinum {
    height: 92px !important;

    div {
        height: 29px;
        background-color: #00a3e2;
        position: absolute;bottom: 0;
        left: 0;
        right: 0;
        color: white;
        padding: 0 10px;

        a {
            color: white;

            p {
                font-size: .7em;
                text-transform: uppercase;
                text-align: center;
                margin: 0;
                line-height: 30px;
                font-weight: bold;
            }
        }
    }
}

.z-index-1 {
    z-index: 1;
}

/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: 960px;
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/**
 * Horizontal rule
 */
hr {
    border: none;
    height: 1px;
    background-color: $grey-color-light;
    margin: 20px 0 40px 0;
    width: 100%;
    display: inline-block;
    
    &.narrow {
        margin: 10px 0;
    }
  
    @include media-query($on-palm) {
      margin: 10px 0 10px 0;  
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}


table {
    width: 100%;
    margin-bottom: 40px;

    @include media-query($on-palm) {
        margin-bottom: 0px;
    }

    table-layout: fixed;

    td {
        vertical-align: top;
        border-left: 5px solid transparent;
        border-top: 10px solid transparent;
        border-right: 5px solid transparent;
        padding: 0;

        &:first-child {
            border-left: 0;
            border-right: 10px solid transparent;
        }

        &:last-child {
            border-right: 0;
            border-left: 10px solid transparent;
        }

        @include media-query($on-palm) {
            display: block;
            /*width: 100%;*/
            margin: 0 0 20px 0;
            border-top: none;
            border-left: none;
            border-right: none;
            
            &.wide {
                display: block;
                width: 100%;
            }

            &:first-child, &:last-child {
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }
    }

    tr:first-child td {
        border-top: 0;
    }
}

.row {
    width: 104%;
    float: left;
    margin: 0;
    position: relative;
    left: -2%;
    right: -2%;
}

.grid-1,
.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6,
.grid-7,
.grid-8,
.grid-9,
.grid-10,
.grid-11,
.grid-12 {
    padding: 0 2% 40px 2%;
    box-sizing: border-box;
    display: block;
    float: left;
    
    @include media-query($on-laptop) {
        &.half {
            width: 48% !important;
        }
      
      &.mobile-wide {
        width: 100% !important;
        padding: 0 2% 20px 2%;
        
        img {
          width: 25%;
          margin: 0 20px 20px 0 !important;
          float: left;
        }
        
        h3, p {
          margin-left: 25%;
          text-align: left;
          padding-left: 20px;
        }
        
        h3 {
          margin-bottom: 0;
        }
      }
    }

    @include media-query($on-palm) {
        width: 100% !important;
        border-right: none !important;
      padding: 0 2% 20px 2%;
        
        /*&.half {
            width: 100% !important;
        }*/
      &.mobile-wide {
        padding: 0 2% 0 2%;
      }
    }
}

.grid-1 {
    width: 8.33333333333333%;

    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-2 {
    width: 16.66666666666666%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-3 {
    width: 25%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-4 {
    width: 33.33333333333332%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-5 {
    width: 41.66666666666665%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-6 {
    width: 50%;
}
.grid-7 {
    width: 58.33333333333331%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-8 {
    width: 66.66666666666664%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-9 {
    width: 75%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-10 {
    width: 83.3333333333333%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-11 {
    width: 91.66666666666663%;
    @include media-query($on-laptop) {
        width: 100%;
        border-right: none !important;
    }
}
.grid-12 {
    width: 100%;
}

input, textarea {
    border: none;
    font-size: 20px;
    color: #666;
    padding: 7px 10px;
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
}

input {
    line-height: 30px;
}

input:focus,
textarea:focus {
    outline:none;
}

textarea {
    resize: none;
    overflow: hidden;
    -webkit-transition: height .25s;
    transition: height .25s;
}

label.placeholder {
    color: #ccc;
    position: relative;
    bottom: 34px;
    left: 10px;
    font-size: 20px;
    -webkit-transition: all .25s;
    transition: all .25s;
    line-height: 20px;
}

label.placeholder:hover {
    cursor: text;
}

input:focus + label.placeholder,
input:valid + label.placeholder,
input.notempty + label.placeholder,
textarea:focus + label.placeholder,
textarea:valid + label.placeholder {
    bottom: -5px;
    font-size: 16px;
}

input:focus + label.placeholder,
textarea:focus + label.placeholder {
    color: #ccc;
}

input:valid + label.placeholder,
textarea:valid + label.placeholder {
    color: $green;
}

.field {
    width: 100%;
    margin: 0 0 20px 0;
    min-height: 66px;
  
  &.no-height {
    min-height: 33px;
    margin: 0;
  }
}

.expand-mobile .expand-content {
  @include media-query($on-palm) {
    height: 0px;
    overflow: hidden;
    transition: all .25s ease-in-out;
  }
}

.expand-mobile.show .expand-content {
  height: auto;
}

.expand-mobile .show-expand {
  display: none;
  @include media-query($on-palm) {
    display: block;
    text-align: center;
    color: $taps-blue;
    background-color: #f6f6f6;
    padding: 5px;
  }
}

.expand-mobile.show .show-expand {
  display: none;
}

.social-link {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0 2%;
  line-height: 30px;
  background-size: 100%;
  
  &.facebook {
    background-image: url('../images/facebook.png');
  }
  
  &.twitter {
    background-image: url('../images/twitter.png');
  }
  
  &.linked-in {
    background-image: url('../images/linkedin.png');
  }

  &.big {
    width: 40px;
    height: 40px;
  }
}

.button {
    float: right;
    font-size: 20px;
    color: white;
    border: 0;
    padding: 5px 10px;
    border-radius: 10px 10px 0 10px;

    &:hover {
        cursor: pointer;
        background: $light-green;
    }
    
    &.centered {
        float: none;
        height: 30px;
        display: inline-block;
        text-align: center;
    }
  
    &.full-width {
      width: 100%;
      text-align: center;
      box-sizing: border-box;
    }
}

@font-face {
font-family: KlavikaWeb;
src: url('../font/KlavikaWebBasic_EOT/KlavikaWebBasicRegular.eot'); /* IE9 Compat Modes */
src: url('../font/KlavikaWebBasic_EOT/KlavikaWebBasicRegular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../font/KlavikaWebBasic_WOFF/KlavikaWebBasicRegular.woff') format('woff'); /* Modern Browsers */
}

@font-face {
font-family: KlavikaWebBold;
src: url('../font/KlavikaWebBasic_EOT/KlavikaWebBasicBold.eot'); /* IE9 Compat Modes */
src: url('../font/KlavikaWebBasic_EOT/KlavikaWebBasicBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../font/KlavikaWebBasic_WOFF/KlavikaWebBasicBold.woff') format('woff'); /* Modern Browsers */
}

@font-face {
font-family: KlavikaWebItalic;
src: url('../font/KlavikaWebBasic_EOT/KlavikaWebBasicLightItalic.eot'); /* IE9 Compat Modes */
src: url('../font/KlavikaWebBasic_EOT/KlavikaWebBasicLightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../font/KlavikaWebBasic_WOFF/KlavikaWebBasicLightItalic.woff') format('woff'); /* Modern Browsers */
}
