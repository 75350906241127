.header {
    width: 100%;
    background-color: $taps-blue;
	background: linear-gradient(135deg, $taps-blue 0%,$light-blue 100%);
    border-bottom: 1px solid #bbb;
    position: fixed;
    z-index: 100;
    top: 0;

    .mountains {
        height: 100px;
        width: 100%;
        position: absolute;
        background-image: url("../images/mountains.png");
        bottom: 0;
        background-position: center;
        z-index: 10;
    }

    .logo {
        //margin: 20px 0;
        float: left;
        position: relative;
        z-index: 100;
        max-width: 340px;
        width: 37.777777777778%;
        //height: auto;
        height: 120px;
        line-height: 120px;
        
        @include media-query($on-laptop) {
            width: 47.552447552448%;
            max-width: 340px;
        }

        @include media-query($on-palm) {
            width: 220px;
            height: 92px;
            line-height: 92px;
        }
    }

    .products-logo {
        float: right;
        padding: 40px 0;

        @include media-query($on-laptop) {
            width: 20%;
        }

        @include media-query($on-palm) {
            display: none;
        }
    }

    .navigation {
        background-image: url('../images/menubg.png');
        z-index: 100;
        position: relative;

        @include media-query($on-palm) {
            position: absolute;
            top: 50%;
            right: 10px;
            left: 10px;
            margin-top: -30px;
            background: none;
            padding: 0;
            border-radius: 0;
            /*border: 1px solid $grey-color-light;*/
            box-sizing: border-box;
            /*min-width: 40px;
            min-height: 40px;*/
            /*transition: background .25s;*/

            /*&:hover {
                padding: 40px 0 0 0;
                left: 10px;
                background: white;
                border: 1px solid $grey-color-light;

                .mobile {
                    top: 6px;
                    right: 4px;
                }
            }*/
            
            /*&:hover .menu, &:hover .mobile-links, &:hover .language {
                display: block;
            }*/
          
          .mobile {

          }
          
          .mobile + label {
            background-image: url('../images/menu.svg');
            background-color: white;
            background-color: rgba(255,255,255,.5);
            padding: 7px 5px;
            background-size: 30px 25px;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 5px;
          }
          
          .menu-wrap {
            display: inline-block;
          }

            .mobile:checked + label + .menu-wrap {
              padding: 40px 0 0 0;
              background: white;
              border: 1px solid $grey-color-light;
              height: auto;
              width: 100%;
              opacity: 1;
              border-radius: 5px;
              transition: all 0s ease, opacity .25s ease-in-out;
          }

            .wrapper {
                padding: 0;
            }
        }
        
      
        
      
        .language {
            display: none;
            position: absolute;
            top: 3px;
            left: 3px;
            margin: 5px;
          
          /*@include media-query($on-palm) {
              display: block;
            }*/
        }
      
      .mobile:checked + label + .menu-wrap .language {
        display: block;
      }
        
        .mobile-links {
            display: none;
            padding: 5px;
            border-top: 1px solid #e6e6e6;
          
            @include media-query($on-palm) {
              display: block;
              float: left;
              width: 100%;
              box-sizing: border-box;
              margin-top: -1px;
            }

            p {
                margin: 0 0 5px 0;
                padding: 5px;
                font-size: 14px;
                transition: all .25s;
                position: relative;

                &:hover {
                    background-color: #f6f6f6;
                }

                &:after {
                    content: "\27A1";
                    position: absolute;
                    right: 5px;
                    top: 5px;
                }
            }
        }
      
      
        @include media-query($on-palm) {
          .menu-wrap {
            transition: all 0s .25s ease, opacity .25s ease-in-out;
            background-color: transparent;
            width: 0;
            height: 0;
            overflow: hidden;
            opacity: 0;
          }
        }

        .menu {
            height: 50px;
            text-align: justify;

            .justify {
                width: 100%;
                display: inline-block;

                @include media-query($on-palm) {
                    display: none;
                }
            }

            a {
                height: 49px;
                line-height: 49px;
                padding: 0 8px;
                display: inline-block;
                margin: 1px 0 0 0;
                font-weight: 400;
                letter-spacing: .04em;
                transition: all .25s;
                background: transparent;
                color: white;
                position: relative;
                //float: left;

                @include media-query($content-width) {
                    letter-spacing: .01em;
                    padding: 0 5px;
                }

                @include media-query($small) {
                    font-size: 14px;
                    padding: 0 2px;
                }

                @include media-query($on-laptop) {
                    padding: 0 5px;
                    margin: 1px 0 0 0;
                    font-weight: 100;
                    letter-spacing: 0;
                    font-size: 14px;
                    width: 12.5%;
                    box-sizing: border-box;
                    text-align: center;
                    float: left;

                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        display: inline-block;
                    }
                }

                &:hover {
                    background: url('../images/15percentwhite.png');
                    background: rgba(255, 255, 255, 0.15);
                }

                &.current {
                    background: url('../images/50percentwhite.png');
                    background: rgba(255, 255, 255, 0.5);
                    color: $taps-blue;

                    &.anchors:after {
                        content: "";
                        width: 13px;
                        height: 6px;
                        background-image: url('../images/arrow.png');
                        position: absolute;
                        bottom: -1px;
                        left: 50%;
                        margin-left: -6.5px;

                        @include media-query($on-palm) {
                            background-image: none;
                        }
                    }
                }

                &.taps-link {
                    height: 25px;
                    float: right;
                    margin: 12.5px 0;
                    line-height: 25px;
                    background: url('../images/50percentwhite.png');
                    background: rgba(255, 255, 255, 0.5);
                    color: $taps-blue;
                    border-radius: 5px;

                    &:hover {
                        background: white;
                    }

                    @include media-query($on-palm) {
                        height: 50px;
                        line-height: 50px;
                        margin: 0;
                    }
                }
            }

            @include media-query($on-palm) {
                /*display: none;*/
                height: auto;
                  display: inline-block;
              float: left;

                a {
                    display: block;
                    text-align: center;
                    border-top: 1px solid #e6e6e6;
                    border-bottom: 1px solid #e6e6e6;
                    color: #00a3e2;
                    max-width: 50%;
                    width: 50%;
                    margin: -1px 0 0 0;
                    padding: 0 10px;
                    float: left;
                    box-sizing: border-box;

                    &:nth-child(odd) {
                        border-right: 1px solid #e6e6e6;
                    }

                    &:hover, &.current {
                        background-color: #f6f6f6;
                    }
                }
            }
        }
      .mobile {
        display: none;
      }
        .mobile + label {
            display: none;
            width: 30px;
            height: 25px;
            float: right;
            position: absolute;
            top: 1px;
            right: 1px;

            @include media-query($on-palm) {
                display: block;
            }
        }
    }
}

.submenu-button {

    @include media-query($on-palm) {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: white;
        border: 1px solid $grey-color-light;
        display: block;
        left: 50%;
        position: fixed;
        margin-left: -25px;
        top: 68px;
        background-image: url('../images/arrow2.png');
        background-repeat: no-repeat;
        background-position: center 32.5px;
        box-shadow: 0 2px 2px rgba(0,0,0,.1);
        z-index: 10;

        &:hover .submenu {
            max-height: 1000px;

            ul li a {
                line-height: 40px;
                color: $taps-blue;
            }
        }

        display: none;
    }
}

.submenu {
    max-width: 880px;
    width: 100%;
    height: 20px;
    background-color: white;
    border: 1px solid $grey-color-light;
    box-shadow: 0 2px 2px rgba(0,0,0,.1);
    border-top: 0;
    border-radius: 0 0 10px 10px;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-sizing: border-box;
    top: 171px;
    transition: height .25s, background-position .25s;;
    z-index: 100;
    overflow: hidden;
    background-image: url('../images/arrow.svg');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center;

    @include media-query($content-width) {
        max-width: 90%;
        width: auto;
        margin:0 5%;
        left:0;
        right: 0;
    }

    @include media-query($on-laptop) {
        ul {
            table-layout: fixed;

            li {
                font-size: 14px;

                a {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    @include media-query($on-palm) {
        top: 92px;
        z-index: 10;
        height: auto !important;
        max-height: 0px;
        transition: max-height .25s;
        background-image: none;
        box-shadow: none;

        &:hover, &.active {
            max-height: 1000px;
            box-shadow: 0 2px 2px rgba(0,0,0,.1);

            ul li a {
                line-height: 40px;
                color: $taps-blue;
            }
        }
    }

    &:hover, &.active {
        height: 40px;
        background-position: center bottom -10px;

        ul li a {
            line-height: 40px;
            color: $taps-blue;
        }
    }

    ul {
        margin: 0;
        list-style: none;
        display: table;
        width: 100%;

        li {
            display: table-cell;
            text-align: center;

            @include media-query($on-palm) {
                width: 100%;
                max-width: 100%;
                text-align: center;
                border-top: 1px solid $grey-color-light;
                float: left;

                &:first-child {
                    border-top: 0;
                }
            }

            a {
                display: block;
                line-height: 20px;
                padding: 0 10px;
                transition: all .25s;
                color: transparent;

                &:hover {
                    background-color: $grey-color-light;
                }
            }
        }

        .break {
            color: #ccc;

            &:first-child {
                display: none;
            }
        }
    }
}

.title {
    margin: 30px 0 0 0;
    display: inline-block;
    width: 100%;
    position: relative;

    @include media-query($on-palm) {
        margin: 0;
    }
    
    .overview-link {
        position: absolute;
        display: inline-block;
        right: 10px;
        width: 80px;
        top: 30px;
        bottom: 10px;
        text-align: center;
        background-image: url('../images/back-arrow.png');
        padding-left: 20px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: left center;
        line-height: 48px;
        height: 48px;
        
        @include media-query($on-laptop) {
            line-height: 32px;
            height: 32px;
        }

        @include media-query($on-palm) {
            line-height: 28px;
            height: 28px;
        }
    }

    h1 {
        margin-bottom: 0;
        margin-top: 20px;
        background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 90%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
        min-height: 32pt;
        line-height: 34pt;
        padding: 10px 20px;
        font-size: 32pt;
        color: $taps-blue;
        position: relative;
        left: -20px;
        bottom: 0px;
        margin-right: -20px;
        
        &.event-title {
            padding: 10px 100px 10px 20px;
        }

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-top: 20px solid rgba(256,256,256,.5);
            border-left: 20px solid transparent;
            position: absolute;
            bottom: -20px;
            left: 0;
        }

        @include media-query($on-laptop) {
            left: -10px;
            font-size: 32px;
            line-height: 32px;
            min-height: 32px;
            padding: 10px;

            &:before {
                border-top: 10px solid rgba(256,256,256,.5);
                border-left: 10px solid transparent;
                bottom: -10px;
            }
        }

        @include media-query($on-palm) {
            font-size: 28px;
            line-height: 28px;
            min-height: 28px;
        }
    }

    .product-logo {
        width: 20%;
        margin: 20px 0 0 0;
        padding: 0 20px 0 0;
        box-sizing: border-box;
        display: inline-block;

        &.front {
            float: left;
            transition: all .15s ease-out;
            transform: scale(1);
        }

        &.front:hover {
            transform: scale(1.1);
        }

        &.front img {
            background: white;
            padding: 5px;
            box-sizing: border-box;
            border-radius: 10px;
        }

        @include media-query($on-laptop) {
            width: 50%;
            float: left;
        }

        @include media-query($on-palm) {
            width: 100%;
        }
    }
}

.center-logos {
    position: relative;
    left: 50%;
    width: 100%;
    margin-left: -180px;

    @include media-query($on-laptop) {
        left: 0;
        margin-left: 0;
    }

    @include media-query($on-palm) {
        left: 0;
        margin-left: 0;
    }
}

.page {
    background: white;
    border-top: 1px solid $grey-color-light;
    padding: 30px 0;
    display: inline-block;
    width: 100%;
    margin: 0 0 30px 0;

    @include media-query($on-laptop) {
        padding: 20px 0;
    }

    @include media-query($on-palm) {
        padding: 10px 0;
    }

    /*.submenu {
        list-style: none;
        float: left;
        width: 35%;
        margin: 0 0 40px 0;
        position: relative;
        padding: 0 40px 0 0;
        top: -$spacing-unit;
        box-sizing: border-box;

        > li a {
            display: block;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            border-bottom: 1px solid $grey-color-light;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all .25s;

            &:hover {
                background-color: $grey-color-light;
            }

            &.current {
                background-color: $taps-blue;
                color: white;

            }
        }

        @include media-query($on-laptop) {
            width: 45%;
        }

        @include media-query($on-palm) {
            width: 100%;
            padding: 0;
        }
    }*/

    .content {
        width: 100%;
        float: right;
        padding: 0 40px;
        box-sizing: border-box;

        @include media-query($on-laptop) {
            padding: 0 20px;
        }

        @include media-query($on-palm) {
            padding: 0 10px;
        }

        & img {
            border: 1px solid #ccc;
            padding: 5px;
            border-radius: 5px;
            box-shadow: 2px 2px 5px #ccc;
            margin: 0 auto 20px auto;
            box-sizing: border-box;
            display: block;

            &.noborder {
                border: none;
                padding: 0;
                border-radius: 0;
                box-shadow: none;
            }
        }

        .post {
            table {
                border-collapse: collapse;
                table-layout: auto;

                img {
                    border: none;
                    padding: 0;
                    border-radius: 0;
                    box-shadow: none;
                    margin: 0;
                }

                td {
                    border-top: none;
                    border-bottom: none;
                    border-right: none;
                    border-left: none;
                    padding: 5px 10px;
                    background: white;

                    &:nth-child(even) {
                        background: #fafafa;
                    }
                }

                tbody tr {
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                }

                thead tr th {
                    padding: 5px 10px;
                }
            }
        }
    }
}

#sidebar {
    ul {
        list-style: none;
        margin-left: 0;
        
        li {
            margin-top: 10px;
        }
    }
}

.footer {
    padding: 30px 0 100px 0;

    .address-table {
        width: 100%;

        & td {
            border-left: 1px solid #ccc;
            padding: 0 20px;
            width: 33%;
            box-sizing: border-box;

            @include media-query($on-laptop) {
                padding: 0 10px;
            }

            @include media-query($on-palm) {
                display: block;
                text-align: center;
                border-left: none;
                width: 100%;
            }
        }
    }
}

.anchor {
    position: relative;
    top: -220px;

    &.top {
        top: -500px;
    }

    @include media-query($on-palm) {
        top: -120px;
    }
}

.wrapper {
    width: 100%;
    max-width: $content-width;
    box-sizing: border-box;
}

.blue {
    @include blue-gradient();
}

.orange {
    @include orange-gradient();
}

.green {
    @include green-gradient();
}

.purple {
    @include purple-gradient();
}

.muted {
    background-color: lighten($taps-blue, 50%);
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #ccc;
}

#customers {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}

.row {

    &.flex {
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
    }

    .tile {
        border-right: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        padding: 20px 2%;

        &:last-child {
            border-right: 1px solid transparent !important;
        }

        img {
            margin-bottom: 10px;
            border: none !important;
            box-shadow: none !important;
        }

        p {
            text-align: center;
        }
    }

    &:last-child .tile {
        border-bottom: 1px solid transparent;

        @include media-query($on-palm) {
            border-bottom: 1px solid #f2f2f2;

            &:last-child {
                border-bottom: 1px solid transparent;
            }
        }

        @include media-query($on-laptop) {
            border-bottom: 1px solid #f2f2f2;

            &:last-child {
                border-bottom: 1px solid transparent;
            }
        }
    }

    &:only-child .tile {
        border-bottom: 1px solid #f2f2f2;
    }
}

.news {
    box-shadow: 2px 2px 5px rgba(0,0,0,.25);
    border-radius: 5px;
    
    .news-link {
        padding: 20px;
        position: relative;
        z-index: 1;
        transition: all .25s;
        border: 1px solid #ccc;
        border-bottom: 0;
        background-color: white;
        
        &:first-child {
            border-radius: 5px 5px 0 0;
        }
        
        &:last-child {
            border-radius: 0 0 5px 5px;
            border-bottom: 1px solid #ccc;
        }

        &:hover {
            transform: scale(1.05);
            z-index: 2;
            box-shadow: 0 0 15px #666 !important;
        }
        
        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        h3 {
            line-height: 26px;
            font-weight: normal;
            margin: 20px 0 0 0;
            color: $taps-blue;
            display: inline-block;
            font-size: 1.12em;
            width: 100%;

            @include media-query($content-width) {
                font-size: 18px;
                line-height: 22px;
            }
        }

        .date {
            float: right;
            font-family: KlavikaWeb;
            margin: 0;
            color: #ccc;
            
            @include media-query($content-width) {
                float: initial;
            }
            
            @include media-query($on-laptop) {
                float: right;
            }
            
            @include media-query($on-palm) {
                float: right;
            }
        }

        span {
            display: inline-block;
            float: left;
            font-family: KlavikaWeb;
            font-size: 12px;
            background-image: url('/images/tags.png');
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 20px;
            color: #bbb;
            max-width: 40%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            
            @include media-query($content-width) {
                float: initial;
                max-width: 100%;
            }
            
            @include media-query($on-laptop) {
                float: left;
            }
            
            @include media-query($on-palm) {
                float: left;
            }
        }
    }
}

.customer {
    text-align: center;
    border-bottom: 1px solid #f2f2f2 !important;

    &:nth-child(4n) {
        border-right: 1px solid transparent !important;
    }

    &.special {
        border-bottom: 1px solid transparent !important;
    }

    a {
        display: block;

        img {
            border: none !important;
            padding: 0 !important;
            border-radius: none !important;
            box-shadow: none !important;
        }

        span {
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
            color: #ccc;
        }
    }

    hr {
        margin: 20px 0 10px 0;
    }
}

.employee {

    @include media-query($content-width) {
        text-align: center;
    }

    h3 {
        margin-bottom: 6px;
        font-size: 1em;
    }

    p {
        font-size: 0.8em;
        line-height: 1.4 !important;
    }

    img {
        height: 200px;
        width: 160px;
        float: left;
        margin: 0 20px 0 0 !important;

        @include media-query($content-width) {
            float: none;
            margin: 0 auto 20px auto !important;
            display: block;
        }
    }
}

.desaturate {
    transition: all .25s;

    /* &:not(:hover) {
        -webkit-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: url("/images/desaturate.svg#greyscale");*/ /* Firefox 10+, Firefox on Android */
        /*filter: grayscale(100%);
        filter: gray;
        -khtml-opacity: 0.5;
        opacity: 0.5;
    } */
}

.slider {
    display: inline-block;
    width: 100%;
    background: white;
    margin-top: 50px;
    float: left;
    overflow: hidden;
    
    ul { 
        display: none;
    }
    
    &.loaded ul { 
        display: block;
    }

    @include media-query($on-palm) {
        margin-top: 20px;
    }

    .bx-wrapper {
        position: relative;

        ul {
            margin: 0;
        }

        .bx-pager {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            bottom: 0;

            @include media-query($on-palm) {
                height: 10px;
            }

            .bx-pager-item {
                display: inline;

                a {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    text-indent: 9999px;
                    background-image: url('../images/dot-inactive.png');

                    @include media-query($on-palm) {
                        background-size: 100%;
                        height: 8px;
                        width: 8px;
                    }

                    &.active {
                        background-image: url('../images/dot.png');
                    }
                }
            }
        }

        .bx-prev, .bx-next {
            position: absolute;
            top: 50%;
            text-indent: 9999px;
            display: block;
            width: 20px;
            height: 20px;
            margin-top: -10px;

            @include media-query($on-palm) {
                display: none;
            }
        }

        .bx-prev {
            left: 10px;
            background-image: url('../images/prev50.png');

            &:hover {
                background-image: url('../images/prev.png');
            }
        }

        .bx-next {
            right: 10px;
            background-image: url('../images/next50.png');

            &:hover {
                background-image: url('../images/next.png');
            }
        }

        li.bx-clone {
            position: absolute !important;
        }
    }
}

.phone {
    background-image: url('../images/phone.png');
    background-repeat: no-repeat;
    height: 50px;
    padding-left: 93px;
    line-height: 50px;
    font-size: 28px;
}

.post {

    img {
        margin: 0 auto;
        display: block;
    }

    h1.blog-title, h3.blog-title {
        margin: 0;
    }

    .meta {
        font-size: 13px;
        font-style: italic;
        margin: 0 0 20px 0;
        color: $grey-color-dark;
    }
}

#platinum {
    width: 200px;
    height: 42px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0 10px 0 0;
    background: url('../images/50percentwhite.png');
    background: rgba(256,256,256,.5);
    padding: 10px;
    border: 1px solid $grey-color-light;
    border-bottom: 0;
    border-left: 0;
    transition: all .25s;
    z-index: 10;

    @include media-query($on-palm) {
        display: none;
    }

    img {
        opacity: .75;
    }

    &:hover {
        background: white;
        border: 1px solid $grey-color;
        border-bottom: 0;
        border-left: 0;

        img {
            opacity: 1;
        }
    }
}

.pagination {
    width: 100%;
    text-align: center;

    .next, .previous {

    }

    .next {
        float: right;
    }

    .previous {
        float: left;
    }
}

.box {
    color: white;
    padding: 10px;

    &.white {
        color: #333;
        background-color: white;
        border: 1px solid #ccc;
    }

    &.rounded {
        border-radius: 5px;
    }

    &.shadow {
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    }

    a {
        color: white;
        text-decoration: underline;
    }
}

.hidden-on-mobile {
  @include media-query($on-palm) {
    display: none;
  }
}

.shown-on-mobile {
  display: none;
  @include media-query($on-palm) {
    display: inline-block;
  }
}

.call-to-action {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    padding: 5px 15px 10px 15px;
    margin: 0 25%;

    & + hr {
        margin: 40px 0 0 0;
        @include media-query($on-palm) {
          margin: 10px 0 10px 0;
        }
    }

    h2 {
        margin-bottom: 10px;
        margin-top: 0;
    }

    .options {
        div {
            width: 33.333%;
            display: inline-block;
            box-sizing: border-box;
            float: left;
            padding: 0 10px;
            border-right: 1px solid white;
            border-right: 1px solid rgba(255,255,255,.5);

            &.first {
                border-right: 1px solid white;
                border-right: 1px solid rgba(255,255,255,.5);
            }

            &.last {
                //border-left: 1px solid white;
                //border-left: 1px solid rgba(255,255,255,.5);
                border-right: 0;
            }

            a {
                width: 100%;
                display: inline-block;
                box-sizing: border-box;
                float: left;
                padding: 0;
                padding-top: 50px;
                background-repeat: no-repeat;
                background-position: center top;
                text-align: center;
                text-decoration: none;
                font-size: 1em;
                font-weight: bold;
                line-height: normal;
                height: 100%;
                transition: all .25s;
                background-size: auto 40px;

                &:hover {
                    transform: scale(1.1);
                    cursor: pointer;
                }

                &.chat {
                    background-image: url('../images/chat-white.png');
                }

                &.mail {
                    background-image: url('../images/mail-white.png');
                }

                &.phone {
                    background-image: url('../images/phone-white.png');
                }
                
                &.register {
                    background-image: url('../images/register.png');
                }
            }
        }
    }
    
    &.event {
        width: 50%;
        margin: 0 25%;

        &.with-sign-up {
            margin: 0 2% 0 15%;
            float: left;
        }
        
        .options div {
            //width: 25%;
        }
        
        @include media-query($content-width) {
            width: 75%;
            margin: 0 12.5%;

            &.with-sign-up {
                width: 75%;
                margin: 0 2% 0 0;
            }
        }
        
        @include media-query($on-laptop) {
            width: 100%;
            margin: 0;

            &.with-sign-up {
                width: 100%;
                margin: 0;
            }
        }

        @include media-query($on-palm) {
            
            .options div {
                width: 100%;
                border: 0;
                border-bottom: 1px solid white;
                border-bottom: 1px solid rgba(255,255,255,.5);
                margin-top: 20px;
                padding-bottom: 20px;
                
                &.last {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    @include media-query($on-laptop) {
        width: 75%;
        margin: 0 12.5%;
    }

    @include media-query($on-palm) {
        width: 100%;
        margin: 0;
    }
}

.register-button {
    width: 16%;
    display: inline-block;
    margin: 0 15% 0 2%;
    float: left;
    box-sizing: border-box;
    padding: 20px 10px;
    text-align: center;
    
    div {
        display: inline-block;
        box-sizing: border-box;
        float: left;
        padding: 0;
        width: 100%;
        
        a {
            width: 100%;
            display: inline-block;
            box-sizing: border-box;
            float: left;
            padding: 0;
            padding-top: 71px;
            background-repeat: no-repeat;
            background-position: center top;
            text-align: center;
            text-decoration: none;
            font-size: 1em;
            font-weight: bold;
            line-height: normal;
            height: 100%;
            transition: all .25s;
            background-size: auto 61px;

            &:hover {
                transform: scale(1.05);
                cursor: pointer;
            }

            &.register {
                background-image: url('../images/register.png');
            }
        }
    }
    
    @include media-query($content-width) {
        width: 21%;
        margin: 0 0 0 2%;
    }
    
    @include media-query($on-laptop) {
        width: 100%;
        margin: 20px 0 0 0;
    }
}

.post .call-to-action {
    width: 80%;
    margin: 0 10%;
    
    @include media-query($on-laptop) {
        width: 75%;
        margin: 0 12.5%;
    }

    @include media-query($on-palm) {
        width: 100%;
        margin: 0;
    }
}

.tech-image {
    padding: 0 !important;
    margin-right: 10px !important;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
    display: inline-block !important;
}

.color-box {
    text-align: center;
    color: white;
    padding: 10px;
    font-size: 1.75em;
    font-weight: bold;
    text-transform: uppercase;

    @include media-query($small) {
        font-size: 1.25em;
    }

    @include media-query($on-laptop) {
        font-size: 1.75em;
    }

    @include media-query($on-palm) {
        font-size: 1.25em;
    }

    &.color-box-75 {
        min-height: 75px;
        line-height: 75px;
    }
}

div.smallish-boxes p.color-box {
    font-size: 1.2em;
}

.opague {
    opacity: .75;
}

#form-messages.error,
#form-messages.success {
    color: white;
    padding: 20px;
    margin-bottom: 40px;
}

#form-messages.error {
    background-color: $orange;
}

#form-messages.success {
    background-color: $green;
}

#form-messages.error:before, #form-messages.success:before {
    margin-right: 10px;
    border: 2px solid white;
    width: 18px;
    height: 18px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    padding: 2px;
}

#form-messages.error:before {
    content: "\2716";
}

#form-messages.success:before {
    content: "\2714";
}

.diploma {
    text-align: center;
    background-image: url('../images/diploma-bg.png');
    background-size: cover;
    background-position: center;

    h3 {
        background: #e5e5e5;
        border: 1px solid #ccc;
        display: inline-block;
        position: relative;
        min-height: 28px;
        color: $taps-blue;
        max-width: 85%;
        padding: 0 1px;

        span {
            width: 100%;
            float: left;
            box-sizing: border-box;
            margin: 1px 0;
            border: 1px dotted #ccc;
            padding: 0 10px;
            line-height: 25px;

            @include media-query($on-laptop) {
                padding: 0 5px;
            }

            @include media-query($on-palm) {
                padding: 0 10px;
            }
        }

        &:before, &:after {
            content: "";
            display: block;
            width: 48px;
            height: 30px;
            position: absolute;
            bottom: -7px;
        }

        &:before {
            background-image: url("../images/ribbon-left.png");
            left: -18px;
        }

        &:after {
            background-image: url("../images/ribbon-right.png");
            right: -18px;
        }
    }
}

img.badge {
    width: 20px;
    height: 20px;
    padding: 0 !important;
    margin: 0 0 0 5px !important;
    float: right;
}

#links {
    float: right;
    margin: 20px 0;
    height: 80px;
    position: relative;
    z-index: 100;
    //width: 350px;
    width: 62.222222222222%;

    .link {
        display: inline-block;
        //color: rgba(255, 255, 255, 0.5);
        color: white;
        //transition: all .25s;
        width: 30%;
        height: auto;
        float: right;
        margin: 0 0 10px 10px;
        margin-left: 2%;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        @include media-query($on-laptop) {
            margin: 45px 0;
            height: 30px;
            width: auto;
            margin-top: 0;
            margin-bottom: 0;
        }
            
        /*&.academy {
            color: white;
            position: relative;

            img {
                opacity: 1;
            }

            .bubble {
                position: absolute;
                background: white;
                width: 135px;
                height: 65px;
                border-radius: 5px;
                border: 1px solid #ccc;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
                left: -155px;
                bottom: -13px;
                padding: 15px 20px;
                box-sizing: border-box;

                @include media-query($on-laptop) {
                    bottom: -10px;
                }

                &:after {
                    content: "";
                    display: block;
                    background-image: url('../images/bubble.png');
                    width: 17px;
                    height: 23px;
                    position: absolute;
                    right: -17px;
                    bottom: 15px;
                }

                p {
                    color: $taps-blue;
                    font-size: 12px;
                    line-height: 17px;
                    margin: 0;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }*/

        h4 {
            font-weight: normal;
            display: inline;
            line-height: 30px;
            margin: 0 0 0 5px;
        }

        img {
            //opacity: .5;
            //transition: all .25s;
            height: 30px;
            width: auto;
        }

        &:hover {
            //color: white;
            cursor: pointer;

            img {
               // opacity: 1;
            }
        }
    }

    @include media-query($on-laptop) {
        //width: 105px;
        width: 52.447552447552%;
        height: 30px;
        margin: 45px 0;
        
        .link {
            display: block;
            margin-bottom: 0;
            margin-left: 8px;
            width: 30px;
            height: 30px;

            img {
                height: 100%;
                width: auto;
            }

            h4 {
                display: none;
            }
        }
        
        .language {
            margin: 5px 0px 5px 5px;
        }
    }

    @include media-query($on-palm) {
        display: none;
    }
}

#twitter {
    iframe {
        height: 557px !important;
        /*margin-top: 50px !important;*/
        width: 1px !important;
        min-width: 100% !important;
        border: 1px solid #ccc !important;
        border-radius: 5px;
        box-sizing: border-box;
    }
}

a.center {
    text-align: center;
    display: block;
}

.frontpage-link {
    color: $text-color;

    img.round {
        border-radius: 50%;
        /*border: none;
        padding: 0;
        box-shadow: none;*/
        margin-bottom: 20px;
        transition: all .25s;
        background-color: white;
    }

    &:hover img.round {
        transform: scale(1.05);
    }
}

img.round {
    border-radius: 50% !important;
    /*border: none;
    padding: 0;
    box-shadow: none;*/
    margin-bottom: 20px;
    transition: all .25s;
    background-color: white;
}

.card {
    display: block;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #ccc;
    position: relative;
    overflow: hidden;

    img {
        border: none !important;
        padding: 0 !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        margin-bottom: 20px;
    }

    a {
        float: none;
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
}

.scale {
    transition: all .25s;

    &:hover {
        transform: scale(1.05);
    }
}

.language {
    border-radius: 50%;
    background: white;
    width: 20px;
    height: 20px;
    margin: 5px 5px 50px 15px;
    background-size: 100%;
    border: 2px solid white;
    float: right;
    
    &.danish {
        background-image: url('../images/danish.jpg');
    }
    
    &.english {
        background-image: url('../images/english.jpg');
    }
    
    @include media-query($on-palm) {
        
    }
}

.read-more {
    color: $taps-blue;
}

/* ACADEMY STYLES */

#course-filter {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;
}

.course-data {
    display: inline-block;
    width: 100%;

    p {
        margin: 0;
    }
}

.course-date {
    position: relative;
    top: -10px;
    right: -10px;
    padding: 10px;
    background-color: $taps-blue;
	background: linear-gradient(135deg, $taps-blue 0%,$light-blue 100%);
    color: white;
    border-radius: 5px;

    h3 {
        margin: 0;
    }

    p.course-heading {
        color: white;
    }

    @include media-query($on-laptop) {
        top: 0;
        right: 0;
    }
}

.course-city {
    position: relative;
    background-color: white;
    left: 0;
    right: 0;
    padding: 0 10px;
    border-radius: 0 0 4px 4px;
    text-align: center;
    color: #00a3e2;
    margin: 0 -9px -9px -9px;
}

.course-availability {
    position: relative;
    background-color: #00a3e2;
    right: -20px;
    margin-top: 20px;
    color: white;
    padding: 5px 0 5px 0;
    height: 20px;
    line-height: 20px;

    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        position: absolute;
        top: 0;
        left: -14px;
    }

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        position: absolute;
        top: 15px;
        left: -14px;
    }


    &.available {
        background-color: $green;

        &:before {
            border-top: 15px solid $green;
        }

        &:after {
            border-bottom: 15px solid $green;
        }
    }

    &.few {
        background-color: $taps-blue;

        &:before {
            border-top: 15px solid $taps-blue;
        }

        &:after {
            border-bottom: 15px solid $taps-blue;
        }
    }

    &.none {
        background-color: $orange;

        &:before {
            border-top: 15px solid $orange;
        }

        &:after {
            border-bottom: 15px solid $orange;
        }
    }

    p {
        margin: 0;
        text-align: center;
    }
}

.more-info {
    float: left;
    width: 100%;
    margin-bottom: 20px;

    & > p {
        text-align: center;
        background-color: $taps-blue;
        border-radius: 5px;
        color: white;
        margin: 0;

        &.open {
            border-radius: 5px 5px 0 0;

            & .arrows {
                transform: rotate(180deg);
            }
        }

        & .arrows {
            width: 10px;
            height: 14px;
            background-image: url('../images/arrows.png');
            background-size: 100%;
            display: inline-block;
            margin: 0px 5px;
            transition: all .25s;
        }

        img {
            margin: 5px;
            display: inline-block;
        }

        &:hover {
            cursor: pointer;
            border-radius: 5px;
        }
    }
}

.info-overlay {
    padding: 20px 20px 0 20px;
    background-color: white;
    z-index: 1;
    display: block;
    border: 1px solid #ccc;
    border-radius: 0 0 5px 5px;
    border-top: 0;
    float: left;
    width: 100%;
    box-sizing: border-box;

    p {
        font-size: 9pt;
    }
}

p.course-heading {
    font-size: 12px;
    font-weight: bold;
    color: #ccc;
    margin-bottom: 0px;
}

.topitem {
    float: left;
    position: relative;
    width: 100%;

    h2 {
        border-bottom: 1px solid #ccc;
    }
}

/*.bubble {
    position: absolute;
    background: white;
    width: 105px;
    height: 65px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    left: 0;
    top: -69px;
    padding: 15px 20px;
    box-sizing: border-box;
    
    &.down {
        
        &:after {
            content: "";
            display: block;
            background-image: url('../images/bubble-down.png');
            height: 17px;
            width: 23px;
            position: absolute;
            left: 20px;
            bottom: -17px;
        }
    }

    @include media-query($on-laptop) {
        bottom: -10px;
    }

    &:after {
        content: "";
        display: block;
        background-image: url('../images/bubble.png');
        width: 17px;
        height: 23px;
        position: absolute;
        right: -17px;
        bottom: 15px;
    }

    p {
        color: $taps-blue;
        font-size: 12px;
        line-height: 17px;
        margin: 0;
        text-align: left;
        white-space: normal;
        overflow: visible;
        text-overflow: inherit;
    }
}*/

/* checkbox foldout list */

/* .topitem.open .subitem {
    max-height: 100%;
}

.topitem label:hover {
    cursor: pointer;
}

.topitem input[type=checkbox] {
    display: none;
}

.topitem input[type=checkbox] + label {
    h2 {
        margin: 0;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        padding-left: 30px;
        transition: all .25s;

        &:before {
            content: "\276F";
            width: 10px;
            height: 10px;
            font-size: 20px;
            line-height: 10px;
            position: absolute;
            letter-spacing: -3px;
            top: 10px;
            left: 0;
            padding: 5px;
            transition: all .25s;
        }
    }

    &.popup {

        p {
            background-color: $taps-blue;
            float: left;
            width: 50%;
            color: white;
            text-align: center;
            margin: 0 25% 20px 25%;
            border-radius: 5px;
            padding: 0 10px;
            box-sizing: border-box;

            @include media-query($on-laptop) {
                width: 100%;
                margin: 0 0 20px 0;
            }
        }
    }
}

.topitem input[type=checkbox]:checked + label {
    color: $taps-blue;

    &.popup {

        &:after {
            content: "\2716";
            position: absolute;
            z-index: 2;
            top: 10px;
            right: 10px;
            font-size: 20px;
        }
    }

    h2:before {
        transform: rotate(90deg);
    }
}

.topitem .subitem {
    max-height: 0;
    transition: max-height .5s ease-out;
    overflow: hidden;

    &.popup {
        max-height: auto !important;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        transition: opacity .25s !important;
        opacity: 0;
    }
}

.topitem input[type=checkbox]:checked + label + .subitem {
    max-height: 10000px;
    transition: max-height .5s ease-in;

    &.popup {
        background-color: white;
        background: rgba(255,255,255,.95);
        padding: 20px;
        opacity: 1;
        transition: opacity .25s !important;
    }
}

.topitem {
    overflow: hidden;
}

.topitem .subitem {
    padding: 10px 0 0 0;
}*/

.pulsate{
    -webkit-animation-name: pulsate;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: pulsate;
    -moz-animation-duration: 2s;
    -moz-animation-timing-function: ease-in-out;
    -moz-animation-iteration-count: infinite;
    animation-name: pulsate;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@-webkit-keyframes pulsate {
    0% {opacity: .75;}
    50% {opacity: 1;}
    100% {opacity: .75;}
}

@-moz-keyframes pulsate {
    0% {opacity: .75;}
    50% {opacity: 1;}
    100% {opacity: .75;}
}

@keyframes pulsate {
    0% {opacity: .75;}
    50% {opacity: 1;}
    100% {opacity: .75;}
}

#academy-attention-grabber-mobile {
    padding: 7px 10px 7px 70px;
    background-color: white;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    display: none;
    position: relative;

    &:before {
        content: "";
        width: 50px;
        height: 50px;
        display: block;
        position: absolute;
        background-image: url('../images/academylogo.png');
        background-size: 100%;
        left: 10px;
        top: 50%;
        margin-top: -25px;
    }
    
    @include media-query($on-palm) {
        display: inline-block;
    }

    span {
        font-weight: bold;
    }
}

#customer-cycle {
    .row {
        width: 100%;
        left: 0%;
        right: 0%;

        .customers {
            transition: all .5s;
            position: relative;
            top: 0;
            left: 0;
            width: 100%;

            &.inactive {
                opacity: 0;
                position: absolute;
                z-index: 0;
            }

            &.active {
                opacity: 1;
                z-index: 1;
            }

            .customer {
                border-bottom: none !important;
            }
        }
    }
}

.color-blue {
    color: $taps-blue;
}

// calendar events

.calendar-event-type {
    font-size: 1.75em;
    color: $taps-blue;
    float: left;
    margin: 0;
}

.calendar-event-date {
    font-size: 1.5em;
    color: $taps-blue;
    float: right;
    margin: 0;
}

.calendar-event-address,
.calendar-event-date-long {
    font-weight: bold;
    font-size: 1.25em;
}

table.calendar-event-times {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0,0,0,.25);
    width: auto;
    border-collapse: collapse;
    margin-bottom: 0;
    
    tr {
        &:first-child {
            td {
                border-bottom: 1px solid #ccc;
            }
        }
        
        td {
            padding: 10px;
            border: none;
            display: table-cell;
        }
    }
}

.calendar-event-item {
    margin-bottom: 20px;
    position: relative;
    
    &.old-event {
        background-color: #f2f2f2;
        
        &:after {
            content: "";
            width: 72px;
            height: 72px;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background-image: url('/images/event-over.png');
        }
        
        .details, h2, p {
            color: #666666;
        }
    }
    
    &.next-event {
        &:after {
            content: "";
            width: 72px;
            height: 72px;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background-image: url('/images/event-next.png');
        }
    }
    
    .details {
        color: #ccc;
        font-family: KlavikaWeb;
        font-size: 1.12em;
        margin: 0;
    }
    
    a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

#event-filter {
    
    .fc-scroller {
        overflow: visible !important;
    }
    
    #calendar-filter {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 2px 2px 5px rgba(0,0,0,.25);    
    }
    
    th.fc-day-header {
        color: #ccc;
        font-weight: normal;
    }
    
    td.fc-head-container.fc-widget-header {
        border-right: none !important;
    }
    
    .fc-center {
        width: 100%;
        
        h2 {
            margin: 0;
            display: inline-block;
            float: none;
        }
        
        button.fc-button {
            border: 0;
            padding: 0;
            background: transparent;

            &.fc-prev-button {
                float: left;
            }

            &.fc-next-button {
                float: right;
            }
        }
    }
    
    .fc th, .fc td {
        border-width: 0;
        border-color: transparent;
    }
    
    .fc-day-grid-event {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        padding: 0;
        float: right;
        
        .fc-content {
            background-color: white;
            border: 1px solid #ccc;
            padding: 2px 5px;
            box-shadow: 2px 2px 5px rgba(0,0,0,.25);
            color: $taps-blue;
            position: absolute;
            width: 100px;
            white-space: initial;
            left: 50%;
            margin-left: -50px;
            top: 15px;
            display: none;
            
            .fc-time {
                display: none;
            }
        }
    }
    
    .fc-other-month {
        background-color: #f6f6f6;
    }
    
    .fc-unthemed .fc-today {
        background-color: lighten($taps-blue, 50%);
    }
    
    @include media-query($on-laptop) {
        .grid-6 {
            width: 100%;
        }
    }
    
    @include media-query($on-palm) {
        table {
            
            td {
                display: table-cell;
            }
        }
    }
    
    table {
        margin: 0;
        
        .fc-body {
            border: 1px solid #ccc;
        }
        
        .fc-row {
            border-bottom: 1px solid #ccc;
            
            &:last-child {
                border-bottom: none;
            }
        }
        
        td {
            border-right: 1px solid #ccc !important;
            
            &:last-child {
                border-left: none !important;
            }
            
            a {
                position: relative;
                right: initial;
                bottom: initial;
                
                .fc-content {
                    overflow: initial;
                }
                
                &:hover .fc-content {
                    display: block;
                }
            }
        }
    }
    
    label {
        padding-right: 20px;
        width: 50%;
        display: inline-block;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        @include media-query($on-palm) {
            width: 100%;
            padding-right: 0;
        }
    }
    
    input[type="checkbox"] {
        width: auto;
    }
    
    .fc-day-grid .fc-row,
    .fc-row .fc-bg,
    .fc-row .fc-content-skeleton {
        z-index: initial;
    }
}

//calendar event widget

.calendar-widget {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0,0,0,.25);
    /*overflow: hidden;*/
    width: 100%;
}

.calendar-widget-event {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    position: relative;
    border-radius: 5px 5px 0 0;
    z-index: 1;
    background-color: white;
    transition: all .25s;
  
    &:hover {
        transform: scale(1.05);
        z-index: 2;
        box-shadow: 0 0 15px #666 !important;
    }
}

.calendar-widget-event a {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.calendar-widget-event-done {
    background-color: #f2f2f2;
}

.calendar-widget-event-done:after, .calendar-widget-event-next:after {
    content: "";
    width: 72px;
    height: 72px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}

.calendar-widget-event-done:after {
    background-image: url('/images/event-over.png');
} 

.calendar-widget-event-next:after {
    background-image: url('/images/event-next.png');
}

.calendar-widget-event-details {
    font-weight: bold;
}

.calendar-widget-event-details {
    color: #ccc;
    font-family: KlavikaWeb;
    line-height: 20px;
    margin-bottom: 10px;
}

.calender-widget-event-title {
    font-size: 1.12em;
    color: #00a1e4;
    margin-bottom: 0;
}

.calendar-widget-event-description {
    font-size: .75em;
    margin-bottom: 0;
    line-height: 1.25em;
}

.calendar-widget-event-done .calendar-widget-event-details,
.calendar-widget-event-done .calender-widget-event-title {
    color: #666666;
}

.category {
    text-decoration: none !important;
    background-color: transparent;
    color: white !important;
    padding: 2px 5px;
    margin: 0 5px 5px 0;
    white-space: nowrap;
    display: inline-block;
    border-radius: 5px;
    transition: all .1s;
    position: relative;
    z-index: 0;
    float: left;
    border: 1px solid white;
    
    &:hover {
        z-index: 1;
        background-color: white;
        color: $green !important;
    }
}

img.no-border {
    border: none !important;
    background: none !important;
    border-radius: none !important;
    box-shadow: none !important;
    padding: 0 40px !important;
}

.event_badge {
    position: fixed;
    left: 20px;
    z-index: 10000;
    top: 20px;
    transition: all .25s ease-in-out;
    padding-right: 50px;
    width: 209px;
    height: 209px;
  
    &:after {
        content: "";
        width: 30px;
        height: 30px;
        background-image: url('/images/event_icon.png');
        background-image: url('/images/event_icon.svg');
        position: absolute;
        right: 200%;
        background-color: white;
        background-color: rgba(255,255,255,.85);
        border: 1px solid #ccc;
        border-radius: 0 5px 5px 0;
        padding: 10px 10px 10px 50px;
        background-repeat: no-repeat;
        background-position: center right 10px;
        transition: all .25s ease-in-out;
    }
  
    @include media-query($badge) {
        top: 200px;
        left: -209px;
      
        &:after {
          right: -1px;
        }

        &:hover {
          left: 20px;
        }

        &:hover:after {
          right: 200%;
        }
    }
  
    @include media-query($on-palm) {
        z-index: 100;
        top: 130px;
  
        &:hover {
          left: 50%;
          margin-left: -104.5px;
        }
    }
}

.pulsate-color {
  animation-name: pulsate-color;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes pulsate-color {
  from {
    opacity: 1;
  }
  to {
    opacity: .5;
  }
}

.pulsate {
  animation-name: pulsate;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes pulsate {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

h4.tech {
    color:#666;
    text-align: center;
    font-weight: normal;
}

p.case-topics {
    text-align: center;
}

span.topic-lozenge {
    background-color: rgba(89, 216, 220, 0.384);
    border: 1px solid rgb(0, 170,221);
    font-size: 8pt;
    border-radius: 5px;
    padding: 3px;
    font-weight: normal;
    color: black;
    font-family: sans-serif;
}

.blue.static.link-call {
    font-style: normal; 
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-mobile-0 {
    @include media-query($on-palm) {
        padding-bottom: 0 !important;
    }
}

.mb-mobile-0 {
    @include media-query($on-palm) {
        margin-bottom: 0 !important;
    }
}