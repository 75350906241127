@charset "utf-8";



// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #333;
$background-color: #FAFAFA;
$brand-color:      #2a7ae2;

$grey-color:       #cccccc;
$grey-color-light: lighten($grey-color, 10%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    960px;

$on-palm:          480px;
$on-laptop:        760px;
$small:            880px;
$mobile:          1024px;
$badge:           1280px;

$taps-blue:        #00a3e2;
$light-blue:       #00e3b4;
$orange:           #e34200;
$light-orange:     #e3b300;
$green:            #39b54a;
$light-green:      #8cc63f;
$purple:           #b400e3;
$light-purple:     #e300a0;

@mixin blue-gradient() {
    background-color: $taps-blue;
    background: linear-gradient(135deg, $taps-blue 0%,$light-blue 100%);
}

@mixin orange-gradient() {
    background-color: $orange;
    background: linear-gradient(135deg, $orange 0%,$light-orange 100%);
}

@mixin green-gradient() {
    background-color: $green;
    background: linear-gradient(135deg, $green 0%,$light-green 100%);
}

@mixin purple-gradient() {
    background-color: $purple;
    background: linear-gradient(135deg, $purple 0%,$light-purple 100%);
}

// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
